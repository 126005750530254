import { Component } from "react";

class WaterSports extends Component {
    componentDidMount() {
        window.location.href = "https://photos.app.goo.gl/PxR64GU66PNsAhRW7";
    }
    render() {
        return true;
    }
}

export default WaterSports;
